import getDevice from "../utils/getDevice";
import postJsonData from "../utils/postJsonData";

const ENDPOINT_EXCEPTIONS = {
    "page-view": "user",
};

const SHORT_FULL_NAME_MAP = {
    dp: "post",
    pp: "post",
    fp: "section",
    sp: "section",
    tp: "tag",
};

const MAX_POST_RM_BEHAVIOR_LOCAL_STORAGE = 50;
const MAX_DAYS_RM_BEHAVIOR_LOCAL_STORAGE = 30;

function getPageTypeName(shortName) {
    return SHORT_FULL_NAME_MAP[shortName] || "public";
}

function getPageTypeFromResourceId() {
    const [resource] = window.REBELMOUSE_BOOTSTRAP_DATA.resourceId.split("_");
    return getPageTypeName(resource);
}

function makeBaseParams() {
    const device = getDevice();
    const siteId = window.REBELMOUSE_BOOTSTRAP_DATA.site.id;
    const roarId = window.REBELMOUSE_BOOTSTRAP_DATA.runner.id;
    return {
        site_id: siteId,
        roar_id: roarId,
        client: device !== "tablet" ? device : "other",
        referrer: document.referrer,
    };
}

function makeSimpleBaseParams(){
    const site_id = window.REBELMOUSE_BOOTSTRAP_DATA.site.id;
    return { site_id };
}

function sendStats(action, params, isSplitTesting) {
    const statsVersion = 1;
    const statsFormat = "json";

    const requestParams = isSplitTesting ? {...makeSimpleBaseParams(), ...params} : {...makeBaseParams(), ...params};

    const query = {
        event: action,
        version: statsVersion,
        format: statsFormat,
        data: JSON.stringify(requestParams),
    };

    const prefix = isSplitTesting ? "split-testing" : ENDPOINT_EXCEPTIONS[action] || "pages";
    const paramsStr = new URLSearchParams(query).toString();

    const statsUrl = `https://${prefix}-stats.rbl.ms/spacer.gif?${Date.now()}&${paramsStr}`;
    const pageViewUrl = `https://pages-stats.rbl.ms/spacer.gif?${Date.now()}&${paramsStr}`;

    fetch(statsUrl);
    if(isSplitTesting && 'post-page-view' == action){
        fetch(pageViewUrl);
    }
}

function addSplitTestingParams(params, splitTesting) {
    const {
        current: { campaign_id, hypothesis_id },
    } = splitTesting;
    return { ...params, campaign_id, hypothesis_id };
}

function checkIfPostHasSplitTesting(post) {
    return post && post.split_testing && post.split_testing.current;
}


function trackPageView(data) {
    const { post, id } = data;
    const pageType = getPageTypeFromResourceId();
    const bootstrapData = window.REBELMOUSE_BOOTSTRAP_DATA;

    let params;
    let isSplitTesting = false;

    if (post) {
        params = {
            post_id: data.id,
            provider_id: post.providerId,
            sections: post.sections,
            buckets: post.buckets,
            authors: post.authors,
        };

        isSplitTesting = checkIfPostHasSplitTesting(post);

        if (isSplitTesting) {
            params = addSplitTestingParams(params, post.split_testing);
        }
    }
    // @NOTE: community/author pages also have "fp" resourceId
    else if (pageType === "section" && !bootstrapData.site.isCommunitySite) {
        const sectionId = bootstrapData.section.id || 0; // @NOTE: 0 is for the home/frontpage (fp)
        params = {
            section_id: sectionId,
            sections: [sectionId],
        };
    }
    else if (pageType === "tag") {
        const tagSlug = bootstrapData.tag.slug;
        params = {
            tag: tagSlug,
            sections: [],
        };
    }
    else {
        params = {
            sections: [],
        };
    }

    const action = isSplitTesting || `${pageType}-page-view`;
    sendStats(action, params, isSplitTesting);
}

function trackTimeOnPage({ timeMarker }) {
    sendStats(`${getPageTypeFromResourceId()}-page-view-duration`, {
        duration: timeMarker,
    });
}

function getUserTrackingId() {
    const trackingId = localStorage.getItem("userTrackingId");
    const prevLoggedStatus = localStorage.getItem("userTrackingLoggedStatus") === "true";
    if (trackingId && prevLoggedStatus === window.REBELMOUSE_BOOTSTRAP_DATA.isUserLoggedIn) {
        return Promise.resolve(trackingId);
    }
    const newVisitorData = {
        user_tracking_id: null,
        last_page_view_timestamp: null,
        user_type: "casual",
    };

    return postJsonData(
        `${window.REBELMOUSE_BASE_SSL_DOMAIN}/core/users/tracking/`,
        newVisitorData
    ).then((data) => {
        const { user_tracking_id } = data;
        localStorage.setItem("userTrackingId", user_tracking_id);
        localStorage.setItem(
            "userTrackingLoggedStatus",
            window.REBELMOUSE_BOOTSTRAP_DATA.isUserLoggedIn
        );
        return user_tracking_id;
    });
}

function cleanUpOtherUserData(currentUserId) {
    const keysToRemove = Object.keys(localStorage)
        .filter(function(key) {
            return key.startsWith('history-') && key !== 'history-' + currentUserId;
        });

    if (keysToRemove) {
        keysToRemove.forEach(function(key) {
            localStorage.removeItem(key);
        });
    }
}

function storePostHistory(userId, id){
    const userHistoryKey = `history-${userId}`;
    const userHistory = localStorage.getItem(userHistoryKey);
    const visitedPosts = userHistory ? JSON.parse(userHistory) : [];
    const idNotStored = !visitedPosts.some(entry => entry.id === id);
    
    if(id && idNotStored){
        const timestamp = new Date().getTime();
        visitedPosts.push({ id, timestamp });

        const daysToTimestamp = 24 * 60 * 60 * 1000
        const expirationTime = new Date().getTime() - (MAX_DAYS_RM_BEHAVIOR_LOCAL_STORAGE * daysToTimestamp);
        const filteredPosts = visitedPosts.filter(entry => entry.timestamp > expirationTime);

        if (filteredPosts.length > MAX_POST_RM_BEHAVIOR_LOCAL_STORAGE) {
            filteredPosts.splice(0, filteredPosts.length - MAX_POST_RM_BEHAVIOR_LOCAL_STORAGE);
        }

        localStorage.setItem(userHistoryKey, JSON.stringify(filteredPosts));
    }

    cleanUpOtherUserData(userId);
}

function trackUser() {
    const mainPost = window.REBELMOUSE_BOOTSTRAP_DATA.post;
    getUserTrackingId().then((trackingId) => {
        storePostHistory(trackingId, mainPost.id);
        const data = {
            user_tracking_id: trackingId,
        };

        if (Object.keys(mainPost).length > 0) {
            data.post_id = mainPost.id;
            data.sections = mainPost.sections;
            data.tags = mainPost.tags;
        }
        sendStats("page-view", data);
    });
}

function trackPostCardClick({ id, post }) {
    const params = {
        post_id: id,
        provider_id: post.providerId,
        sections: post.sections,
    };

    const isSplitTesting = checkIfPostHasSplitTesting(post);

    if (isSplitTesting) {
        sendStats("post-card-click", addSplitTestingParams(params, post.split_testing), true);
    } else {
        sendStats("post-card-click", params);
    }
}

function trackPostCardView({ id, post }) {
    const params = {
        site_id: window.REBELMOUSE_BOOTSTRAP_DATA.site.id,
        roar_id: window.REBELMOUSE_BOOTSTRAP_DATA.runner.id,
        sections: post.sections,
        posts:[{
            post_id: id,
            provider_id: post.providerId,
            authors: post.auhtors,
        }]
    };

    sendStats("batch-post-card-view", params);
}

function batchPostCardView(posts) {
    sendStats("batch-post-card-view", { posts }, true);
}

export default {
    trackPageView,
    trackTimeOnPage,
    trackUser,
    trackPostCardClick,
    trackPostCardView,
    batchPostCardView
};
